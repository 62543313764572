import React from 'react'
import '../../styles/home.css'
import { useState } from 'react'

import { HashLink } from 'react-router-hash-link';


function Home() {
  const [isScrolled, setIsScrolled] = useState(false)

  const handleScroll = () => {
    if(window.scrollY > 100) {
      setIsScrolled(true)
    }
    if(window.scrollY <50) {
      setIsScrolled(false)
    }
  }

  const textEnglish = [{
    title: 'Masterpieces Forged with Precision and Passion',
    text: 'Traian stands at the forefront of wrought iron craftsmanship, blending traditional techniques with contemporary elegance. Our exclusive wrought iron creations - from imposing gates to sophisticated railings and unique furniture - are expertly forged to be timeless symbols of resilience and refinement.'
},
{
  title: 'Explore Our Artisanal Collections'
},
{
    title: 'Elegant Gates',
    text: 'Icons of luxury and protection, seamlessly blending aesthetics with function.'
},
{
    title: 'Sophisticated Railings',
    text: 'Artfully designed railings, combining safety with striking visual appeal.'
},
{

    title: 'Bespoke Designs',
    text: 'Where your vision meets our expertise – crafting one-of-a-kind ironworks.'
}
]

const textSerbian = [{
    title: 'Remek-dela koja su spoj umetnosti i tradicije',
    text: 'Traian je na čelu zanatske veštine kovanja gvožđa, spajajući tradicionalne tehnike sa savremenom elegancijom. Naše ekskluzivne kreacije od kovanog gvožđa - od impozantnih kapija do sofisticiranih ograda i unikatnog nameštaja - vešto su kovane da budu trajni simboli otpornosti i prefinjenosti.'
},
{
  title: 'Istražite naše kolekcije'
},
{
    title: 'Elegantne Kapije',
    text: 'Ikone luksuza i zaštite, besprekorno spajajući estetiku i funkcionalnost.'
},
{
    title: 'Sofisticirane Ograde',
    text: 'Vešto dizajnirane ograde, kombinujući bezbednost sa upečatljivom vizuelnom privlačnošću.'
},
{

    title: 'Unikatni Dizajni',
    text: 'Gde se Vaša vizija susreće sa našim iskustvom - stvarajući jedinstvena dela od gvožđa.'
}
];

var language = sessionStorage.getItem('language');

  window.addEventListener('scroll', handleScroll)

  return (
    <div className='home-page'>
    <div className='image'>
    </div>
    <div className='about'>
        <div className='about-text'>
            <h1>
            {language==="english"?textEnglish[0].title:textSerbian[0].title}
            </h1>
            <p>
            {language==="english"?textEnglish[0].text:textSerbian[0].text}
            </p>
        </div>
    </div>
    <div className='image2'>
    
        <img src={require('../../images/Home/2.png')} alt='elegant gate' />
    </div>
    <div className='our-collections'>
        <h1>
        {language==="english"?textEnglish[1].title:textSerbian[1].title}
        </h1>
        <div className='categories'>
            <div className='product-category'>
            <HashLink
             to='/gallery#gates'>
                <h3>
                {language==="english"?textEnglish[2].title:textSerbian[2].title}
                </h3>
                <p>
                {language==="english"?textEnglish[2].text:textSerbian[2].text}
                </p>
                <img src={require('../../images/Home/PC1Gate.jpg')} alt='Traian Gate 01'/>
              </HashLink>
            </div>
            <div className='product-category'>
              <HashLink
              smooth
              
              to='/gallery#railings'
              
              >
                <h3>
                {language==="english"?textEnglish[3].title:textSerbian[3].title}
                </h3>
                <p>
                {language==="english"?textEnglish[3].text:textSerbian[3].text}
                </p>
                <img src={require('../../images/Home/PC2Railing.jpg')} alt='Traian Railing 01'/>
              </HashLink>
            </div>
            <div className='product-category'>
              <HashLink
               to='/gallery#designs'>
                <h3>
                {language==="english"?textEnglish[4].title:textSerbian[4].title}
                </h3>
                <p>
                {language==="english"?textEnglish[4].text:textSerbian[4].text}
                </p>
                <img src={require('../../images/Home/PC3Design.jpg')} alt='traian designs' />
              </HashLink>
            </div>
        </div>
    </div>
</div>

  )
}

export default Home