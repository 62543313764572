import React from 'react'

function Viewr() {
  return (
  <>
    
  </>
  )
}

export default Viewr